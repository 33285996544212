
































import Vue from 'vue'
import Toolbar from '@/components/Toolbar.vue'
import API from '@/utils/API'
import Receipt from '@/models/Receipt'

export default Vue.extend({
  name: 'DistributorInformation',
  components: {
    Toolbar
  },
  data: function () {
    return {
      username: '',
      receipt: {} as Receipt,
      errorMessage: ''
    }
  },
  methods: {
    back () {
      this.$router.go(-1)
    }
  },
  mounted () {
    const id = this.$route.params.id
    API.getReceipt(id).then(it => {
      this.receipt = it
    }).catch(e => {
      this.errorMessage = e
    })
    this.username = window.localStorage.getItem('username') || ''
  }
})
